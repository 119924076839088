<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="scopedSlots" slot-scope="{ columnData }">
				<a-button type="link" @click="payDetail(columnData)">Payment Details</a-button>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="Detail(columnData.loanId)">{{ $t('page.checkDetail') }}</a>
			</template>
		</search-list-table>
		<a-modal v-drag-modal title="Payment Details" width="800px" v-model="payDetailsModal" :footer="false">
			<div v-for="(item, idx) in payDetailsList" :key="idx">
				<a-descriptions bordered size="default">
					<template slot="title">{{ $t('table.applyInfo.amountCloums.pay') + ' ' + (idx + 1) }}</template>
					<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payName')" :span="3">{{ item.disburseName }}</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payAccount')" :span="3"> {{ item.disburseAccount }}</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payType')" :span="3"> {{ item.disburseType }}</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payAmount')" :span="3"> {{ item.disburseAmount }}</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payTime')" :span="3"> {{ item.paidTime }}</a-descriptions-item>
					<a-descriptions-item :label="$t('table.Disbursement-Receipt')" :span="3">
						<UploadPreviewFile :uploadFilePath="item.disburseCertificateFile" :isUploadFileBtn="false"></UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { apiBranchList } from '@/api/branch'
import { apiIpfDisburse } from '@/api/insuranceRenewal'
export default {
	data() {
		return {
			branchOfficeList: [],
			payDetailsModal: false,
			payDetailsList: []
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.caseId',
					type: 'input',
					validateField: ['caseId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.payTime',
					type: 'range-picker',
					validateField: ['paidTime', { initialValue: [] }]
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				},
				{
					label: 'Closed Date',
					type: 'range-picker',
					validateField: ['closeTime', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'id',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					title: 'IPF Renewal Disbursed Amount',
					dataIndex: 'disburseAmount',
					align: 'center',
					customRender: (v, o) => {
						return <a-statistic precision={0} value={o.disburseAmount === null ? ' - ' : o.disburseAmount} />
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.payTime'),
					dataIndex: 'paidTime',
					align: 'center'
				},
				{
					// 更新状态
					title: 'Payment',
					align: 'center',
					scopedSlots: { customRender: 'scopedSlots' }
				},
				{
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 150,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiIpfDisburse(initParams)
		},
		payDetail(columnData) {
			this.payDetailsModal = !this.payDetailsModal
			this.payDetailsList = columnData.payees
		},
		Detail(id) {
			this.$router.push({
				name: 'menus.customerManagementMenu.lendingdetail',
				query: {
					id
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
